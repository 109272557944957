import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  title = 'Pink Skillet Terms of Service';

  constructor(
    private route: ActivatedRoute, 
    private location: Location,
    private titleService: Title,
    private meta: Meta
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.meta.updateTag({name: 'keywords', content: 'Recipe'});
    this.meta.updateTag({name: 'description', content: 'Pink Skillet Terms of Service.'});
    this.meta.updateTag({name: 'author', content: 'Hiltos LLC'});
    this.meta.updateTag({name: 'robots', content: 'index, follow'});
  }

}
