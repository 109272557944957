import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { AuthService } from './services/auth.service';

import * as firebase from "firebase/app";
import "firebase/analytics";
import { SearchService } from './services/search.service';

import { version } from 'src/assets/version.js';
import { fadeInAnimation } from 'src/app/animations/fade-in-animation';

declare global {
  interface Document {
      documentMode?: any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeInAnimation]
})
export class AppComponent implements OnInit, AfterViewInit{

  title = 'Pink Skillet Recipe Database';
  isLoading: boolean;
  searchFormHeight: string;
  magicSearchBtnColor: string;
  showMagicSearchUI: boolean;
  magicSearchUIOpacity: number;
  searchTextInput: string;
  isSearchClearBtnVisible: boolean;
  browserSupported: boolean;
  isOperaBrowser: boolean;
  slideMenuRight: string;

  screenHeight: number;
  screenWidth: number;

  appVersion: string;

  /* Selected Search Category */
  searchCategory: string;

  constructor (
    public router: Router, 
    public authService: AuthService, 
    public searchService: SearchService,
    private titleService: Title,
    private meta: Meta
    ) { 
    this.getScreenSize();
  };

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  };

  @HostListener('window:resize', ['$event'])
  getScreenSize(_event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    //console.log(this.screenHeight, this.screenWidth);
  };

  public lessThanOrEqualTo(subj: number, num: number) {
    return subj <= num;
  };
  
  ngAfterViewInit(): void {
  };

  ngOnInit(): void {
    this.appVersion = version;
    this.titleService.setTitle(this.title);
    this.meta.updateTag({name: 'keywords', content: 'Recipe'});
    this.meta.updateTag({name: 'description', content: 'Pink Skillet solves the what should I make for dinner problem in a fun, intuitive, and accessible way for everyone.'});
    this.meta.updateTag({name: 'author', content: 'Hiltos LLC'});
    this.meta.updateTag({name: 'robots', content: 'index, follow'});
    firebase.analytics();
    //this.browserSupported = false;
    // Internet Explorer? if so, forget it...
    if(/msie\s|trident\/|edge\//i.test(window.navigator.userAgent)) { 
      this.browserSupported = false; 
    }
    else {
      this.browserSupported = true;
    };

    this.isOperaBrowser = /opera\/|OPR\//i.test(window.navigator.userAgent)

    this.slideMenuRight = '-350px';
    this.searchFormHeight = "60%";
    this.magicSearchBtnColor = "#FFAADD";
    this.magicSearchUIOpacity = 0;
    this.searchCategory = "Recipes";
    this.isSearchClearBtnVisible = false;
    this.authService.isLoading = true;
  };

  searchInputChanged(value: string) {
    this.searchTextInput = value;
    this.searchService.searchTextInput.next({textInput: value});
    if(this.searchTextInput == '') {
      this.isSearchClearBtnVisible = false;
    } 
    else {
      this.isSearchClearBtnVisible = true;
    };
  };

  changeCategory(newCat: string) {
    this.searchCategory = newCat;
    this.searchService.searchCategory.next({category: newCat});
  };

  toggleMagicSearch() {
    if(this.searchFormHeight == "60%") {
      this.searchFormHeight = "38px";
      this.magicSearchBtnColor = "#EB3770";
      (<any>$('#magic-search-button')).tooltip('hide');
      (<any>$('#magic-search-button')).tooltip('disable');
      this.showMagicSearchUI = true;
      this.magicSearchUIOpacity = 1;
    }
    else {
      this.searchFormHeight = "60%";
      this.magicSearchBtnColor = "#FFAADD";
      (<any>$('#magic-search-button')).tooltip('enable');
      this.magicSearchUIOpacity = 0;
      this.showMagicSearchUI = false;
    };
  };

  signIn() {
    this.authService.signIn();
  };

  signOut() {
    this.authService.signOut();
  };

  goToMyProfile() {
    this.router.navigate(['/',this.authService.loggedUser.person.urlSlug]);
  };

  clearSearch() {
    (<any>$('#searchInput')).val('');
    (<any>$('#searchInput')).focus();
    this.isSearchClearBtnVisible = false;
    this.searchService.searchTextInput.next({textInput: ''});
  };

  openMenu(e: Event): void {
    e.stopPropagation();
    this.slideMenuRight = '0px';
  };

  closeMenu(): void {
    this.slideMenuRight = '-350px';
  };

}
