import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import "@firebase/auth";
import "@firebase/analytics";
import "@firebase/firestore";
import "@firebase/functions";


@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  auth: () => firebase.auth.Auth;
  functions: () => firebase.functions.Functions;
  firestore: () => firebase.firestore.Firestore;
  
  constructor() {
    return firebase.initializeApp({
      apiKey: "AIzaSyBheBrvAdBCOr1WMFNAT-nVWa6BBVjZ76w",
      authDomain: "pinkskillet.com",
      databaseURL: "https://pink-skillet.firebaseio.com",
      projectId: "pink-skillet",
      storageBucket: "pink-skillet.appspot.com",
      messagingSenderId: "935723642524",
      appId: "1:935723642524:web:e01e3b6b760833b8708759",
      measurementId: "G-G7JW8N6JL6"
    });
  };
};
