import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { TermsComponent } from './pages/terms/terms.component';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { RecipesComponent } from './pages/recipes/recipes.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PersonComponent } from './pages/person/person.component';
import { PeopleComponent } from './pages/people/people.component';
import { RecipeListComponent } from './pages/recipe-list/recipe-list.component';
import { CollectionsComponent } from './pages/collections/collections.component';
import { IngredientsComponent } from './pages/ingredients/ingredients.component';
import { PantriesComponent } from './pages/pantries/pantries.component';
import { CollectionListComponent } from './pages/collection-list/collection-list.component';
import { IngredientListComponent } from './pages/ingredient-list/ingredient-list.component';
import { PantryListComponent } from './pages/pantry-list/pantry-list.component';
import { RecipeComponent } from './pages/recipe/recipe.component';
import { CollectionComponent } from './pages/collection/collection.component';
import { IngredientComponent } from './pages/ingredient/ingredient.component';
import { PantryComponent } from './pages/pantry/pantry.component';


const routes: Routes = [
  { path: '', component: AppComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'people', component: PeopleComponent },
  { path: 'recipes', component: RecipesComponent },
  { path: 'collections', component: CollectionsComponent },
  { path: 'ingredients', component: IngredientsComponent },
  { path: 'pantries', component: PantriesComponent },
  { path: ':perid', component: PersonComponent, children: [
    { path: 'recipes', component: RecipeListComponent },
    { path: 'recipes/:recid', component: RecipeComponent },
    { path: 'collections', component: CollectionListComponent },
    { path: 'collections/:colid', component: CollectionComponent },
    { path: 'ingredients', component: IngredientListComponent },
    { path: 'ingredients/:ingid', component: IngredientComponent },
    //{ path: 'pantries', component: PantryListComponent },
    { path: 'pantries/:panid', component: PantryComponent }
  ] },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule],
  providers: [
      { provide: LocationStrategy, useClass: PathLocationStrategy }
  ]
})
export class AppRoutingModule { }
