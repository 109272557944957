import { Injectable, NgZone } from '@angular/core';
import * as firebase from 'firebase/app';
import { FirebaseService } from './firebase.service';
import { Observable, Observer, BehaviorSubject } from 'rxjs';
import { User } from '../models/user.model';

@Injectable({ providedIn: 'root' })
export class AuthService {

  auth: firebase.auth.Auth;
  authState: Observable<firebase.User>;
  theUser: firebase.User;
  loggedUser: User;
  usernameTextInput: BehaviorSubject<{textInput:string}> = new BehaviorSubject({textInput:''});
  userTag: string;
  userSuggest: string;
  isLoading: boolean;

  constructor(public app: FirebaseService, private ngZone: NgZone) {
    this.loggedUser = null;
    this.auth = this.app.auth();
    this.authState = this.authStateObservable();

    //this.app.functions().useFunctionsEmulator('http://localhost:5001');

    this.authStateObservable().subscribe(u => {
      //console.log("authStateObservable ... subscribe");
      this.theUser = u;
      console.log(this.theUser);
      if(u){
        var getPerson = this.app.functions().httpsCallable('auth-getUser');
        var temp = this;
        this.ngZone.run( () =>
          getPerson({uid: u.uid}).then(function(result) {
            console.log(result.data);
            if(result.data.error === undefined) {
              let lu: User = result.data;
              temp.loggedUser = lu;
            }
            else {
              console.log(result.data.error);
            }
            temp.isLoading = false;
          })
          .catch(function(error) {
            console.log(error);
            temp.isLoading = false;
          })
        );
      }
      else {
        this.loggedUser = null;
        this.isLoading = false;
      };
    });

    this.auth.getRedirectResult().then(function(result) {
      //console.log("getRedirectResult ... then");
    }).catch(function(error) {
      console.log(error);
    });
  };

  /**
   * @function
   * @desc Create an Observable of Firebase authentication state
   */
  public authStateObservable(): Observable<firebase.User> {
    const authState = Observable.create((observer: Observer<firebase.User>) => {
      this.auth.onAuthStateChanged(
        (user?: firebase.User) => { observer.next(user); },
        (error: firebase.auth.Error) => observer.error(error),
        () => observer.complete()
      );
    });
    return authState;
  };

  public signIn() {
    this.isLoading = true;
    var provider = new firebase.auth.GoogleAuthProvider();
    this.auth.signInWithRedirect(provider);
  };

  public signOut() {
    this.isLoading = true;
    this.auth.signOut();
  };
}