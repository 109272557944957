<div *ngIf="browserSupported; else browser_not_supported">
    <div class="toolbar" role="banner" (click)="closeMenu()">
        <div *ngIf="lessThanOrEqualTo(screenWidth, 575.98); else use_h1_logo">
          <a routerLink="/"><img id="mainlogoh2" alt="Pink Skillet Logo" src="../assets/logo-57x48-color.svg" /></a>
        </div>
        <div *ngIf="lessThanOrEqualTo(screenWidth, 575.98); else use_h1_logo_text">
          <a routerLink="/"><h2 class="logo">Pink Skillet</h2></a>
        </div>
        <ng-template #use_h1_logo>
          <a routerLink="/"><img id="mainlogoh1" alt="Pink Skillet Logo" src="../assets/logo-70x59-color.svg" /></a>
        </ng-template>
        <ng-template #use_h1_logo_text>
          <a routerLink="/"><h1 class="logo">Pink Skillet</h1></a>
        </ng-template>
      <div class="spacer"></div>
      <img *ngIf="authService.isLoading; else not_loading" class="usermenu pb-1" src="../assets/coffee-spinner-32.gif" />
      <ng-template #not_loading>
        <div *ngIf="authService.loggedUser; else no_user" class="usermenu dropdown">
          <button class="btn profilepic" type="button" style="background-image: url({{authService.theUser.photoURL}});"
            data-toggle="dropdown" aria-haspopup="true" tabindex="0" aria-expanded="false"></button>
          <div class="dropdown-menu dropdown-menu-right dropdown-menu-shadow">
            <p class="dropdown-item-no-hover dropdown-item-greeter"><small class="text-muted">Good to see you!</small></p>
            <p class="dropdown-item-no-hover">{{authService.loggedUser.person.username}}<small class="text-muted"> ({{authService.loggedUser.person.urlSlug}})</small><br>{{authService.loggedUser.email}}</p>
            <div class="dropdown-divider"></div>
            <button class="btn btn-linklike dropdown-item" type="button">My Recipes</button>
            <button class="btn btn-linklike dropdown-item" type="button">My Collections</button>
            <button class="btn btn-linklike dropdown-item" type="button">My Pantry</button>
            <button class="btn btn-linklike dropdown-item" type="button" (click)="goToMyProfile()">My Profile</button>
            <div class="dropdown-divider"></div>
            <button class="btn btn-linklike dropdown-item" type="button" (click)="signOut()">Sign Out</button>
          </div>
          <!--
          <ng-template #no_username>
            <div class="dropdown-menu dropdown-menu-right dropdown-menu-shadow" style="width: 20rem;">
              <p class="dropdown-item-no-hover dropdown-item-greeter"><small class="text-muted">Pleased to meet you,</small></p>
              <p class="dropdown-item-no-hover">{{authService.theUser.displayName}}<br>{{authService.theUser.email}}</p>
              <div class="dropdown-divider"></div>
              <div class="dropdown-item-no-hover" style="font-size: 14px;">
                <p>Before you begin listing your recipes, pick a username to use on Pink Skillet.</p>
              </div>
              <button class="btn btn-linklike dropdown-item" type="button" (click)="createUsername()">Pick a Username</button>
              <div class="dropdown-divider"></div>
              <button class="btn btn-linklike dropdown-item" type="button" (click)="signOut()">Sign Out</button>
            </div>
          </ng-template>
          -->
        </div>

        <ng-template #no_user>
            <div class="usermenu dropdown">
              <button class="btn profilepic" type="button" aria-label="Log in" data-toggle="dropdown" aria-haspopup="true"
                tabindex="0" aria-expanded="false"><fa-icon icon="user-circle" size="2x" class="fa-profile-icon"></fa-icon></button>
              <div class="dropdown-menu dropdown-menu-right dropdown-menu-shadow">
                <button id="google-signin-btn" class="btn dropdown-item" (click)="signIn()"></button>
                <div class="dropdown-divider"></div>
                <form class="px-4 py-2">
                  <div class="form-group mb-0">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="dropdownCheck" style="margin-top: 0.4rem; margin-left: 0;">
                      <label class="form-check-label" for="dropdownCheck" style="margin-left: 28px;">
                        Remember me
                      </label>
                    </div>
                  </div>
                </form>
              </div>
            </div>
        </ng-template>

      </ng-template>
        <button class="btn btn-menu-bars" type="button" (click)="openMenu($event)"
          aria-label="Main menu" tabindex="0"><fa-icon icon="bars"></fa-icon></button>
    </div>

    <div class="slideMenu" role="menu" [style.right]="slideMenuRight">
      <div class="container">
        <div class="row">
          <div class="col-sm mt-3 pl-4">
            <div class="slide-menu-close-btn">
              <button class="btn btn-menu-bars-close mr-1" type="button" (click)="closeMenu()"
          aria-label="Close menu" tabindex="0"><fa-icon icon="times"></fa-icon></button>
            </div>
            <div *ngIf="authService.loggedUser">
              <h6>Get Active</h6>
              <a routerLink="/">Add a Recipe</a><br>
              <a routerLink="/">Share Your Creations</a>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm mt-4 pl-4">
            <h6>Get Help</h6>
            <a routerLink="/">Email Us</a><br>
            <a routerLink="/">Support</a><br>
            <a routerLink="/">FAQs</a>
          </div>
        </div>
        <div class="row">
          <div class="col-sm mt-4 pl-4">
            <h6>Get Connected</h6>
            <a routerLink="/">Facebook</a><br>
            <a routerLink="/">Twitter</a><br>
            <a routerLink="/">Instagram</a>
          </div>
        </div>
        <div class="row">
          <div class="col-sm mt-4 pl-4">
            <h6>Get Informed</h6>
            <a routerLink="/">About</a><br>
            <a routerLink="/">Blog</a><br>
            <a routerLink="/">Sources</a><br>
            <a routerLink="/">Affiliates</a>
          </div>
        </div>
      </div>
    </div>

    <div class="content" role="main" (click)="closeMenu()">
      <div *ngIf="router.url === '/'; else use_outlet">
        <div class="homevideobox">
          <div *ngIf="lessThanOrEqualTo(screenWidth, 575.98); else use_larger_video">
            <video id="home-video" muted="muted" loop="loop" preload="auto" autoplay="autoplay" class="homevideo">
              <source src="../assets/video/video1.webm" type="video/webm">
              <source src="../assets/video/video1.mp4" type="video/mp4">
              <source src="../assets/video/video1.ogg" type="video/ogg">
              Your browser doesn't support embeded video!
            </video>
          </div>
          <ng-template #use_larger_video>
            <video onloadeddata="this.play();" muted="muted" loop="loop" preload="auto" class="homevideo">
              <source src="../assets/video/video1.mp4" type="video/mp4">
              <source src="../assets/video/video1.ogg" type="video/ogg">
              <source src="../assets/video/video1.webm" type="video/webm">
              Your browser doesn't support embeded video!
            </video>
          </ng-template>
          <div class="overlay">
            <form class="form-inline form-inline-center-fix mt-3" [style.height]="searchFormHeight">
              <div class="input-group home-search-width">
                <div class="input-group-prepend">
                  <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {{(searchService.searchCategory | async)?.category}}
                  </button>
                  <div class="dropdown-menu dropdown-menu-shadow" style="line-height: 1.5;">
                    <button class="btn dropdown-item ml-0" (click)="changeCategory('Recipes')">Recipes</button>
                    <button class="btn dropdown-item ml-0" (click)="changeCategory('Collections')">Collections</button>
                    <button class="btn dropdown-item ml-0" (click)="changeCategory('Ingredients')">Ingredients</button>
                    <button class="btn dropdown-item ml-0" (click)="changeCategory('People')">People</button>
                    <button class="btn dropdown-item ml-0" (click)="changeCategory('Everything')">Everything</button>
                  </div>
                </div>
                <input type="text" class="form-control remove-right-border" id="searchInput" #searchInput placeholder="Search Pink Skillet"
                  onfocus="this.placeholder=''" onblur="this.placeholder='Search Pink Skillet'" (keyup)="searchInputChanged(searchInput.value)">
                <button id="clear-search-button" class="btn btn-clear-search" [class.btn-clear-search-show]="isSearchClearBtnVisible" type="button" (click)="clearSearch()"><fa-icon icon="times"></fa-icon></button>
                <div class="input-group-append">
                  <button id="magic-search-button" class="btn btn-magic-search" [style.color]="magicSearchBtnColor" type="button" 
                    (click)="toggleMagicSearch()" data-toggle="tooltip" data-html="true" 
                    data-placement="bottom" data-animation="true" title="<em>Magic Search!</em>"><fa-icon icon="magic"></fa-icon></button>
                  <button class="btn btn-primary" type="button"><fa-icon icon="search"></fa-icon></button>
                </div>
              </div>
            </form>
            <div *ngIf="showMagicSearchUI">
              <app-magic-search-ui class="magic-search-ui" [style.opacity]="magicSearchUIOpacity"></app-magic-search-ui>
            </div>
          </div>
        </div>

        

      </div>
      <ng-template #use_outlet>
        <div [@routeAnimations]="prepareRoute(outlet)" >
          <router-outlet #outlet="outlet"></router-outlet>
        </div>
      </ng-template>
    </div>

    <div class="container" (click)="closeMenu()">
      <div class="row justify-content-center mt-2 mb-2">
        <footer>© 2020 Hiltos | <a routerLink="/terms">Terms of Service</a> | <a routerLink="/privacy">Privacy Policy</a></footer>
      </div>
      <div class="row justify-content-center mt-2 mb-2">
        <footer><small class="text-muted">Version {{appVersion}}</small></footer>
      </div>
    </div>


</div>

<ng-template #browser_not_supported>
  <div class="toolbar justify-content-center" role="banner">
    <h2 class="logo">Pink Skillet</h2>
  </div>
  <div class="content-ie" role="main">
    <h5>Uh oh...</h5>
    <p>
      You're using a browser that we don't support.
    </p>
    <p>
      We suggest trying a browser that supports modern web standards such as any of these:
    </p>
    <div class="container">
      <div class="row">
        <div class="col text-center">
          <p><fa-icon [icon]="['fab', 'firefox-browser']" [fixedWidth]="true" size="2x"></fa-icon> <a target="_blank" href="https://www.mozilla.org/en-US/firefox/new/">Firefox</a></p>
        </div>
        <div class="col text-center">
          <p><fa-icon [icon]="['fab', 'chrome']" [fixedWidth]="true" size="2x"></fa-icon> <a target="_blank" href="https://www.google.com/chrome/">Chrome</a></p>
        </div>
        <div class="col text-center">
          <p><fa-icon [icon]="['fab', 'edge']" [fixedWidth]="true" size="2x"></fa-icon> <a target="_blank" href="https://www.microsoft.com/edge">Edge</a></p>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row justify-content-center mt-2 mb-2">
      <footer>© 2020 Hiltos</footer>
    </div>
  </div>
</ng-template>
