import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { TermsComponent } from './pages/terms/terms.component';
import { FontAwesomeModule, FaIconLibrary, FaConfig } from '@fortawesome/angular-fontawesome';
import { faBars, faTimes, faMagic, faSearch, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { faFirefoxBrowser, faOpera, faChrome, faEdge, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { MagicSearchUiComponent } from './fragments/magic-search-ui/magic-search-ui.component';
import { RecipesComponent } from './pages/recipes/recipes.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PersonComponent } from './pages/person/person.component';
import { PeopleComponent } from './pages/people/people.component';
import { RecipeComponent } from './pages/recipe/recipe.component';
import { RecipeListComponent } from './pages/recipe-list/recipe-list.component';
import { CollectionComponent } from './pages/collection/collection.component';
import { CollectionListComponent } from './pages/collection-list/collection-list.component';
import { CollectionsComponent } from './pages/collections/collections.component';
import { IngredientComponent } from './pages/ingredient/ingredient.component';
import { IngredientListComponent } from './pages/ingredient-list/ingredient-list.component';
import { IngredientsComponent } from './pages/ingredients/ingredients.component';
import { PantryComponent } from './pages/pantry/pantry.component';
import { PantryListComponent } from './pages/pantry-list/pantry-list.component';
import { PantriesComponent } from './pages/pantries/pantries.component';

@NgModule({
  declarations: [
    AppComponent,
    PrivacyComponent,
    TermsComponent,
    MagicSearchUiComponent,
    RecipesComponent,
    PageNotFoundComponent,
    PersonComponent,
    PeopleComponent,
    RecipeComponent,
    RecipeListComponent,
    CollectionComponent,
    CollectionListComponent,
    CollectionsComponent,
    IngredientComponent,
    IngredientListComponent,
    IngredientsComponent,
    PantryComponent,
    PantryListComponent,
    PantriesComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    FontAwesomeModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary, faConfig: FaConfig) {
    library.addIcons(
      faBars,
      faTimes, 
      faMagic, 
      faSearch, 
      faFirefoxBrowser, 
      faOpera, 
      faChrome, 
      faEdge,
      faGoogle,
      faUserCircle
    );
  }
 }
