import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  searchCategory: BehaviorSubject<{category:string}> = new BehaviorSubject({category:'Recipes'});
  searchTextInput: BehaviorSubject<{textInput:string}> = new BehaviorSubject({textInput:''});

  constructor() { }


}
