import { Component, OnInit } from '@angular/core';
import { SearchService } from '../../services/search.service';

@Component({
  selector: 'app-magic-search-ui',
  templateUrl: './magic-search-ui.component.html',
  styleUrls: ['./magic-search-ui.component.scss']
})
export class MagicSearchUiComponent implements OnInit {

  constructor(public searchService: SearchService) { }

  ngOnInit(): void {
  }

  clearAll(): void {
    this.searchService.searchCategory.next({category: 'Recipes'});
    this.searchService.searchTextInput.next({textInput: ''});
    (<any>$('#searchInput')).val('');
    (<any>$('#searchInput')).focus();
  }

}
