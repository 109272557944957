<div *ngIf="authService.isLoading; else not_loading">
  <div class="row justify-content-center mt-5 mb-2">
    <img src="../../../assets/coffee-spinner-64.gif" />
  </div>
  <div class="row justify-content-center mt-2 mb-2">
    Loading...
  </div>
</div>

<ng-template #not_loading>
  <div *ngIf="authService.loggedUser; else is_visitor">
    <div *ngIf="routeID == authService.loggedUser.person.urlSlug; else is_visitor">
      <div *ngIf="authService.loggedUser.uid == authService.auth.currentUser.uid; else not_logged">
        <p>Hello {{authService.loggedUser.person.username}}!</p>
        <router-outlet></router-outlet>
      </div>
      <ng-template #not_logged>
        <p>Please log in to view your profile settings.</p>
      </ng-template>
    </div>
  </div>

  <ng-template #is_visitor>
    <p>Hello Visitor!</p>
  </ng-template>
</ng-template>
