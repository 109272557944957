// import the required animation functions from the angular animations module
import { trigger, animate, transition, style, query } from '@angular/animations';

export const fadeInAnimation =
  trigger('routeAnimations', [
    // route 'enter' transition
    transition(':enter', [

      // css styles at start of transition
      style({ opacity: 0 }),

      // animation and styles at end of transition
      animate('.3s', style({ opacity: 1 }))
  ]),
]);
