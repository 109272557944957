import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Person } from 'src/app/models/person.model';

@Component({
  selector: 'app-person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.scss']
})
export class PersonComponent implements OnInit {

  public routeID: string;

  constructor(
    public authService: AuthService,
    private route: ActivatedRoute
    ) { }

  ngOnInit(): void {
    //this.routeID = this.route.snapshot.paramMap.get('perid');
    this.route.params.subscribe(params => {
      this.routeID = params['perid'];
    });
    console.log(this.routeID);
  }

  //currentUser().uid == authService.loggedPerson.uid && route paramID == authService.loggedPerson.urlSlug

}
