<div class="card magic-search-ui-container">
  <div class="card-body">
    <p class="card-text">Searching for <i>{{(searchService.searchCategory | async)?.category}}</i></p>
    <div *ngIf="(searchService.searchTextInput | async)?.textInput; else no_search_input">
      <p class="card-text">with text containing <i>"{{(searchService.searchTextInput | async)?.textInput}}"</i>.</p>
    </div>
    <ng-template #no_search_input>
      <p class="card-text">with text containing anything.</p>
    </ng-template>

  </div>
  <div class="card-footer">
    <button id="magic-search-clear-btn" class="btn btn-secondary btn-sm" (click)="clearAll()">Clear All</button>
    <button id="magic-search-submit-btn" class="btn btn-primary btn-sm">Abracadinner</button>
  </div>
</div>